import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { PowerBIEmbed } from 'powerbi-client-react';
import { TokenType, BackgroundType } from 'powerbi-models';
import './PowerBI.css';
import powerbiApi from './PowerBI.api';
import SpinningSpark from '../shared/components/SpinningSpark';

export default function PowerBI() {
  const [loading, setLoading] = useState(false);
  const [credentials, setCredentials] = useState(null);

  useEffect(() => {
    setLoading(true);
    powerbiApi
      .getPowerBICredentials()
      .then((response) => setCredentials(response.data))
      .catch(console.error)
      .finally(() => setLoading(false));
  }, []);

  const embedConfig = {
    type: 'report',
    tokenType: TokenType.Embed,
    accessToken: credentials?.embedToken,
    embedUrl: credentials?.url,
    settings: {
      panes: {
        filters: {
          expanded: false,
          visible: false,
        },
      },
      background: BackgroundType.Transparent,
    },
  };

  return (
    <Box marginBottom="3vh">
      {loading ? (
        <Box display="flex" alignItems="center" justifyContent="center">
          <SpinningSpark />
        </Box>
      ) : (
        <PowerBIEmbed
          embedConfig={embedConfig}
          cssClassName="report-container"
        />
      )}
    </Box>
  );
}
